export const mq = {
  xs: '@media screen and (max-width: 575px)',
  sm: '@media screen and (min-width: 576px) and (max-width: 767px)',
  md: '@media screen and (min-width: 768px) and (max-width: 991px)',
  lg: '@media screen and (min-width: 992px) and (max-width: 1199px)',
  xl: '@media screen and (min-width: 1200px) and (max-width: 3400px)',
  lt: {
    sm: '@media screen and (max-width: 575px)',
    md: '@media screen and (max-width: 990px)',
    lg: '@media screen and (max-width: 1198px)',
    xl: '@media screen and (max-width: 3400px)',
  },
  gt: {
    xs: '@media screen and (min-width: 576px)',
    sm: '@media screen and (min-width: 768px)',
    md: '@media screen and (min-width: 992px)',
    lg: '@media screen and (min-width: 1200px)',
  },
};
