import React, { useEffect } from 'react';
import FormContext from '../../../context/form-context';
import { Form } from './form';
import { Response } from './response';

export const ContactUs = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  return (
    <FormContext.Consumer>
      {(data) => {
        if (data.success || data.failure) {
          return <Response close={data.close} type={data.success ? 'success' : 'failure'} />;
        }
        return <Form close={data.close} onSuccess={data.setSuccess} onFailure={data.setFailure} />;
      }}
    </FormContext.Consumer>
  );
};
