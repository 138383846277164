import React from 'react';

export const WaveForm = () => (
  <svg width="100%" height="100%" viewBox="0 0 895 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M123.748 65.576C107.563 108.949 36.6237 61.383 3.68894 25.3259C-6.97349 13.6526 1.78812 0 17.598 0H1332.66C1352.13 0 1352.08 2.86943 1334.08 10.3043C1315.09 18.1495 1295.35 33.5951 1287.71 65.576C1286.41 71.0268 1284.97 75.2771 1283.44 78.472C1272.47 101.352 1208.13 67.3908 1183.05 71.2887C1129.67 79.5885 1096.45 -2.15124 1065.7 15.4832C1013.03 45.6836 922.515 125.879 986.867 65.576C1001.32 52.0331 720.416 30.8629 708.54 46.7137C708.129 47.2621 707.729 47.8371 707.339 48.4394C642.6 148.399 658.083 22.8159 531.175 60.2935C527.323 61.431 523.551 62.4419 519.857 63.3332C497.498 68.7277 276.142 26.3964 258.082 40.6391C141.847 132.306 157.532 -24.9649 123.748 65.576Z"
      fill="#FBFBFB"
    />
  </svg>
);
