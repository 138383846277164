import React from 'react';
import Modal from './src/components/modules/modal';
import FormContext, { FormProvider } from './src/context/form-context';

export const wrapRootElement = ({ element }) => {
  return (
    <FormProvider>
      <FormContext.Consumer>{(data) => <Modal open={data.isOpen} />}</FormContext.Consumer>
      {element}
    </FormProvider>
  );
};
