import React from 'react';

const defaultState = {
  isOpen: false,
  success: false,
  failure: false,
  open: () => {},
  close: () => {},
  setSuccess: () => {},
  setFailure: () => {},
};

const FormContext = React.createContext(defaultState);

class FormProvider extends React.Component {
  state = {
    isOpen: false,
    success: true,
    failure: false,
  };

  open = () => {
    this.setState({ isOpen: true, success: false, failure: false });
  };

  close = () => {
    this.setState({ isOpen: false, success: false, failure: false });
  };

  setSuccess = () => {
    this.setState({
      success: true,
      failure: false,
    });
  };

  setFailure = () => {
    this.setState({
      success: false,
      failure: true,
    });
  };

  render() {
    const { children } = this.props;
    const { isOpen, success, failure } = this.state;
    return (
      <FormContext.Provider
        value={{
          isOpen,
          success,
          failure,
          setSuccess: this.setSuccess,
          setFailure: this.setFailure,
          open: this.open,
          close: this.close,
        }}
      >
        {children}
      </FormContext.Provider>
    );
  }
}

export default FormContext;

export { FormProvider };
