import React from 'react';

export const ResponseWave = () => (
  <svg width="524" height="72" viewBox="0 0 524 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M288.831 49.6264C285.647 50.5575 282.531 51.3835 279.48 52.1104C261.148 56.4781 81.0485 22.1537 66.1975 33.755C42.7228 52.093 25.6558 60.3876 13.0242 61.45C4.9657 62.1278 0.0456647 54.2673 0.111772 46.1806C0.251284 29.1146 0.461632 1.62131 0.37474 0.938084C0.263406 0.0626696 407.762 0.00215969 503.869 6.18959e-05C515.102 -0.000183308 524 9.10653 524 20.3401V32.8416C524 44.39 514.322 53.1998 503.472 49.2449C476.225 39.313 446.17 19.0604 432.444 40.0539C379.667 120.774 392.289 19.3624 288.831 49.6264Z"
      fill="#FBFBFB"
    />
    <path
      d="M66.1975 33.755C93.6199 12.3332 146.857 98.1552 168.169 13.4411C168.169 26.9897 206.064 73.8376 288.831 49.6264C392.289 19.3624 379.667 120.774 432.444 40.0539C449.772 13.5507 493.125 52.7844 524 54V0.000544937C524 0.000544937 0.248522 -0.0543607 0.37474 0.938084C0.500958 1.93053 0 59.4923 0 59.4923C13.1621 65.3765 33.4417 59.3432 66.1975 33.755Z"
      fill="#FBFBFB"
    />
  </svg>
);
