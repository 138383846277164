import React from 'react';

export const PenIcon = () => (
  <svg width="106" height="70" viewBox="0 0 106 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.7119 12.2897C35.6419 12.6696 36.5232 13.2136 37.3129 13.9239L97.1427 67.7326C100.703 70.935 101.116 76.3715 98.079 80.0744L91.3554 88.2728C88.136 92.1984 82.301 92.6668 78.4966 89.3051L17.8102 35.6812C16.6646 34.6689 15.84 33.4296 15.3436 32.091L0.137511 0.70874L34.7439 12.2533L34.7119 12.2897ZM95.1458 69.9529C97.5196 72.0878 97.7946 75.7121 95.7701 78.1807L89.0464 86.3792C86.9001 88.9963 83.0102 89.3086 80.4739 87.0674L19.7875 33.4435C19.031 32.775 18.4845 31.958 18.1526 31.0753L32.5524 14.7395C33.5487 14.9482 34.5049 15.4146 35.3161 16.1442L95.1458 69.9529Z"
      fill="#FF8310"
    />
    <path d="M0.129883 0.718756L7.83756 3.26453L3.67236 7.98971L0.129883 0.718756Z" fill="white" />
    <path
      d="M103.422 73.3255C106.463 76.0638 106.733 80.7401 104.027 83.8101L94.7411 94.3442C92.0147 97.4372 87.2972 97.7343 84.2043 95.0079L79.7241 91.0587C76.6312 88.3323 76.334 83.6148 79.0604 80.5219L88.3877 69.9407C91.1343 66.8247 95.8961 66.5499 98.983 69.3292L103.422 73.3255Z"
      fill="#FF8310"
    />
  </svg>
);
