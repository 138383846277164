import React from 'react';

export const CheckIcon = () => (
  <svg width="114" height="107" viewBox="0 0 114 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 50.6691L39.7814 102.255C41.4127 104.483 44.7659 104.397 46.2812 102.089L112 2"
      stroke="#FF8310"
      strokeWidth="4.7482"
    />
  </svg>
);
