import React, { useState } from 'react';
import { ArrowIcon } from '../../icons/ArrowIcon';
import { QuestionIcon } from '../../icons/QuestionIcon';
import { PenIcon } from '../../icons/PenIcon';
import * as S from './styles';
import { WaveForm } from '../../icons/WaveForm';
import emailjs from '@emailjs/browser';

const schema = {
  name: /[\S\s]+[\S]+/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  subject: /[\S\s]+[\S]+/,
  message: /[\S\s]+[\S]+/,
};

export const Form = ({ close, onSuccess, onFailure }) => {
  const [form, setForm] = useState({ name: '', email: '', subject: '', message: '' });
  const [errors, setErrors] = useState({ name: false, email: false, subject: false, message: false });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {
      name: false,
      email: false,
      subject: false,
      message: false,
    };
    for (const key in schema) {
      errors[key] = !schema[key].test(form[key]);
    }
    setErrors({
      ...errors,
    });
    return !Object.values(errors).some((v) => v === true);
  };

  const sendEmail = async (e) => {
    try {
      const res = await emailjs.send('service_8d3nvhk', 'template_w0hd5ae', form, 'pvo1JAXWF9apIulPz');
      if (res.status === 200) {
        onSuccess();
      } else {
        onFailure();
      }
    } catch (error) {
      onFailure();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendEmail();
    }
  };

  return (
    <S.FormDialog>
      <S.Wave>
        <WaveForm />
      </S.Wave>
      <S.Question>
        <QuestionIcon />
      </S.Question>
      <S.Pen>
        <PenIcon />
      </S.Pen>
      <S.Title>Message Us</S.Title>
      <S.Form onSubmit={handleSubmit}>
        <S.Input placeholder="Name" error={errors['name']} onChange={handleChange} name="name" />
        <S.Input placeholder="E-mail" error={errors['email']} onChange={handleChange} name="email" />
        <S.Input placeholder="Subject" error={errors['subject']} onChange={handleChange} name="subject" />
        <S.Input placeholder="Message" error={errors['message']} onChange={handleChange} name="message" />
        <S.Actions>
          <S.SendButton type="submit">
            <ArrowIcon color="#ffffff" rotate={270} />
            Send Message
          </S.SendButton>
          <S.CloseButton onClick={close}>Close</S.CloseButton>
        </S.Actions>
      </S.Form>
    </S.FormDialog>
  );
};
