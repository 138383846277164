import React from 'react';
import { ContactUs } from '../contactus';
import { Form } from '../contactus/form';
import Portal from '../portal';
import * as S from './styles';

const Modal = ({ open }) => {
  if (open) {
    return (
      <Portal>
        <S.Overlay />
        <ContactUs />
      </Portal>
    );
  }

  return null;
};

export default Modal;
