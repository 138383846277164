import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

const modal = css`
  position: absolute;
  top: 50%;
  z-index: 33;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000000;
  border: 1px solid #ffffff;
  border-radius: 16px;
  overflow: hidden;
`;

export const FormDialog = styled.div`
  ${modal};
  padding: 115px 62px 62px 62px;
  color: #ffffff;
  max-width: 895px;
  ${mq.lt.md} {
    width: 95%;
    padding: 96px 32px 32px 32px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  > * {
    flex-basis: 100%;
    flex-grow: 1;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      flex-basis: 40%;
    }
    ${mq.lt.md} {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        flex-basis: 100%;
      }
    }
  }
`;

export const Input = styled.input`
  padding: 14px 12px 19px 12px;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2rem;
  border-radius: 8px;
  color: #575757;
  border: ${({ error }) => (error ? '2px solid red' : 'none')};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 38px;
`;

export const SendButton = styled.button`
  background: transparent;
  color: #ffffff;
  font-size: 2rem;
  line-height: 2.4rem;
  padding: 12px 18px 12px 12px;
  border: 1px solid #8b8b8b;
  border-radius: 16px;
  svg {
    margin-right: 12px;
  }
`;

export const CloseButton = styled.span`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 4.3rem;
  margin-bottom: 32px;
`;

export const Question = styled.div`
  position: absolute;
  right: 38px;
  top: 27px;
  ${mq.lt.md} {
    svg {
      width: 50px;
    }
  }
`;

export const Pen = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: 29%;
  ${mq.lt.md} {
    left: 100%;
    transform: translateX(-100%);
    svg {
      width: 50px;
      transform: translateY(-100%);
      left: 23%;
    }
  }
`;

export const Wave = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
`;

export const Response = styled.div`
  ${modal};
  color: #ffffff;
  padding: 84px 100px 67px 100px;
  max-width: 524px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq.lt.md} {
    width: 95%;
    padding: 96px 32px 32px 32px !important;
  }
`;

export const ResponseTitle = styled.div`
  font-size: 5.6rem;
  line-height: 6.7rem;
  margin-bottom: 14px;
`;

export const ResponseMessage = styled.div`
  font-size: 2rem;
  line-height: 2.6rem;
  text-align: center;
  margin: 24px 0 35px 0;
`;

export const ResponseButton = styled.button`
  padding: 12px 18px 12px 18px;
  border: 1px solid #8b8b8b;
  width: 90%;
  border-radius: 16px;
  font-size: 2rem;
  background: transparent;
  color: #ffffff;
`;
