import React from 'react';

export const QuestionIcon = () => (
  <svg width="92" height="129" viewBox="0 0 92 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7286 21.3093C24.8506 8.02329 52.1714 -9.83974 80.7134 24.5605C80.9109 24.7985 81.109 25.0797 81.267 25.3454C109.583 72.9644 30.2105 76.722 21.6299 92.5621C13.2324 109.148 12.914 111.813 11.7004 113.602M8.56601 119.289C6.69764 122.426 5.81984 123.849 3.99902 126.979"
      stroke="#FF8310"
      strokeWidth="8"
    />
  </svg>
);
