import React from 'react';
import { CloseIcon } from '../../icons/CloseIcon';
import { CheckIcon } from '../../icons/CheckIcon';
import * as S from './styles';
import { ResponseWave } from '../../icons/ResponseWave';

const data = {
  success: {
    title: 'Success!',
    message: 'Your application has been accepted. You will be contacted shortly for further details',
    icon: CheckIcon,
  },
  failure: {
    title: 'Error!',
    message: 'Something went wrong. Please try again!',
    icon: CloseIcon,
  },
};

export const Response = ({ type, close }) => {
  const Icon = data[type].icon;
  return (
    <S.Response>
      <S.Wave>
        <ResponseWave />
      </S.Wave>
      <S.ResponseTitle>{data[type].title}</S.ResponseTitle>
      <Icon />
      <S.ResponseMessage>{data[type].message}</S.ResponseMessage>
      <S.ResponseButton onClick={close}>Close</S.ResponseButton>
    </S.Response>
  );
};
